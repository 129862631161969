import axios, { AxiosResponse } from "axios";
import { RequestInitiation } from "../models/RequestInitiation";
import { Ticket } from "../models/Ticket";
import { TicketSearch } from "../models/TicketSearch";
import { TicketResult } from "../models/TicketResult";
import { TicketComment } from "../models/TicketComment";
import { Blurb } from "../models/Blurb";
import { InspoImage, InspoImageType } from "../models/InspoImage";
import { ColorSeasons } from "../models/ColorSeasons";
import { StatusCount } from "../models/StatusCount";
import { ErrorLog } from "../models/ErrorLog";
import { UnassociatedPayment } from "../models/UnassociatedPayment";

const apiRootUrl = 'https://kaylicolor-apim-prod.azure-api.net/api';
//const apiRootUrl = 'http://localhost:7071/api';

//TODO: Don't call this every api call
//TODO: Environment configs instead of hard coded
const setHeaders = (emailForVerificationCode:string|undefined = undefined) => {
    axios.defaults.headers.common['accept'] = `application/json`;
    axios.defaults.headers.common['Accept-Language'] = `en-US,en;q=0.8`;
    axios.defaults.headers.common['VerificationCode'] = emailForVerificationCode ? getVerificationCode(emailForVerificationCode) : '';
    axios.defaults.headers.common['AdminCode'] = localStorage.getItem("adminCode");
    axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = 'ac89859d30974a8492260bb24a553c2a';
}

const getVerificationCode = (email : string) => {
    let requestsJson = localStorage.getItem("colorAnalysisRequests");
    let verificationCode = undefined;
    if(requestsJson && email)
    {
      let parsedRequests = JSON.parse(requestsJson) as RequestInitiation[];
      verificationCode = parsedRequests.find(r => r.email === email)?.verificationCode
    }

    return verificationCode;
}

export const KayliColorAPI = {
  standardError: 'Server could not be reached. Please try again later.',
  tryLater: 'Please try again later.',

  createResultImage: async function (email: string, file: any) {
    let data = new FormData();
    data.append('File', file, file.name);
    return axios.post(`${apiRootUrl}/tickets/${email}/result-image`, data, {
        headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${file.size}`,
            'VerificationCode': getVerificationCode(email),
            'AdminCode': localStorage.getItem("adminCode")
      }
      })
    },

    createRequestImage: async function (email: string, files: any): Promise<AxiosResponse<Ticket>> {
        let data = new FormData();
        for(var i = 0;i<files.length;i++) {
            data.append('File'+i.toString(), files[i], files[i].name);
        }
        return axios.post(`${apiRootUrl}/tickets/${email}/request-images`, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${files[0].size}`,
                'VerificationCode': getVerificationCode(email),
                'AdminCode': localStorage.getItem("adminCode")
          }
        });
    },

    createMainRequestImage: async function (email: string, file: any, isAlt: Boolean) {
        let data = new FormData();
        data.append('File', file, file.name);
        return axios.post(`${apiRootUrl}/tickets/${email}/request-images-main/${isAlt}`, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${file.size}`,
                'VerificationCode': getVerificationCode(email),
                'AdminCode': localStorage.getItem("adminCode"),
                'Ocp-Apim-Subscription-Key': 'ac89859d30974a8492260bb24a553c2a'
          }
        })
    },

    setMainRequestImageString: async function (ticket: Ticket): Promise<AxiosResponse<Ticket>> {
        setHeaders(ticket.email);
        return axios.patch(`${apiRootUrl}/tickets/request-images-main-set`, ticket);
    },


    createTicket: async function (email: string): Promise<AxiosResponse<Ticket>> {
        setHeaders();
        let data = { email };
        return axios.post(`${apiRootUrl}/tickets`, data);
    },

    getTicket: async function (email: string): Promise<AxiosResponse<Ticket>> {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/tickets/${email}`);
    },

    updateTicket: async function (data: Ticket) {
        setHeaders(data.email);
        return axios.patch(`${apiRootUrl}/tickets`, data);
    },

    resultsOpened: async function (email: string): Promise<AxiosResponse<Ticket>> {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/tickets/${email}/opened`);
    },

    submitTicket: async function (email: string) {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/tickets/${email}/submit`);
    },

    needsMoreInfo: async function (email: string) {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/tickets/${email}/needsMoreInfo`);
    },

    searchTickets: async function (data: TicketSearch) {
        setHeaders();
        return axios.post(`${apiRootUrl}/tickets/search`, data);
    },


    getUnassociatedPayments: async function (): Promise<AxiosResponse<UnassociatedPayment[]>> {
        setHeaders();
        return axios.get(`${apiRootUrl}/PayPal/payment/unassociated`);
    },

    updateUnassociatedPayment: async function (transactionId: string, email: string): Promise<AxiosResponse<UnassociatedPayment>> {
        setHeaders();
        return axios.post(`${apiRootUrl}/PayPal/payment/unassociated/${transactionId}/${email}`);
    },


    sendEmailVerification: async function (email: string) {
        setHeaders();
        return axios.get(`${apiRootUrl}/tickets/${email}/verification`);
    },

    checkEmailVerification: async function (data: RequestInitiation) {
        setHeaders();
        return axios.post(`${apiRootUrl}/tickets/verification`, data);
    },


    getQueueManagementData: async function (): Promise<AxiosResponse<StatusCount[]>>  {
        setHeaders();
        return axios.get<StatusCount[]>(`${apiRootUrl}/QueueManagement`);
    },

    moveXToReadyToPay: async function (num: number): Promise<AxiosResponse<string>>  {
        setHeaders();
        return axios.post<string>(`${apiRootUrl}/QueueManagement/${num}`);
    },

    moveXToImportedEmailed: async function (num: number): Promise<AxiosResponse<string>>  {
        setHeaders();
        return axios.post<string>(`${apiRootUrl}/QueueManagement/imported/${num}`);
    },


    createComment: async function (data: TicketComment) {
        setHeaders(data.email);
        return axios.post(`${apiRootUrl}/tickets/comments`, data);
    },


    fakePayment: async function (email: string) {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/tickets/${email}/payment/fake`);
    },

    createPaypalOrder: async function (email: string) {
        setHeaders(email);
        return axios.post(`${apiRootUrl}/PayPal/${email}/order`);
    },

    getPaypalOrder: async function (email: string) {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/PayPal/${email}/order`);
    },

    capturePaypalOrder: async function (email: string, orderId: string) {
        setHeaders(email);
        return axios.get(`${apiRootUrl}/PayPal/${email}/order/${orderId}/capture`);
    },

    logPaypalerror: async function (data: ErrorLog) {
        setHeaders();
        return axios.post(`${apiRootUrl}/PayPal/log`, data);
    },


    getResult: async function (email: string): Promise<AxiosResponse<TicketResult>> {
        setHeaders(email);
        return axios.get<TicketResult>(`${apiRootUrl}/tickets/${email}/results`);
    },

    updateResult: async function (data: TicketResult) {
        setHeaders();
        return axios.patch(`${apiRootUrl}/tickets/results`, data);
    },

    publishResult: async function (email: string) {
        setHeaders();
        return axios.get(`${apiRootUrl}/tickets/${email}/results/publish`);
    },


    getAllInspoImages: async function () {
        setHeaders();
        return axios.get<InspoImage[]>(`${apiRootUrl}/inspo-images`);
    },

    deleteInspoImage: async function (url: string, type: InspoImageType) {
        setHeaders();
        return axios.delete<boolean>(`${apiRootUrl}/inspo-images/${url}/${type}`);
    },

    uploadInspoImage: async function (season: ColorSeasons, inspoType: InspoImageType, file: any) {
        let data = new FormData();
        data.append('File', file, file.name);
        return axios.post(`${apiRootUrl}/inspo-images/${season}/${inspoType}`, data, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${file.size}`,
                'AdminCode': localStorage.getItem("adminCode")
          }
        })
    },


    getAllBlurbs: async function () {
        setHeaders();
        return axios.get<Blurb[]>(`${apiRootUrl}/blurbs`);
    },

    createBlurb: async function (blurb: Blurb) {
        setHeaders();
        return axios.post(`${apiRootUrl}/blurbs`, blurb);
    },

    updateBlurb: async function (blurb: Blurb) {
        setHeaders();
        return axios.put(`${apiRootUrl}/blurbs`, blurb);
    },

    deleteBlurb: async function (id: string) {
        setHeaders();
        return axios.delete(`${apiRootUrl}/blurbs/${id}`);
    },
}