import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '../../models/Ticket';
import { Footer } from '../../components/Footer';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Header } from '../../components/Header';
import { ColorFrames } from '../../models/ColorFrames';
import { ColorSeasons } from '../../models/ColorSeasons';
import { ColorFrameType } from '../../models/ColorFrameType';

const Palettes: React.FC = () => {

  return (
    <div>
      {false && (<Header/>)}
      <div className="centerIt">
        <br></br>
        Here you can find all of my color palettes for all the seasons for downloading! Every season has both colors and neutrals.
        <br></br><br></br>
        <h4>Cool Summer</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.CoolSummer && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.CoolSummer && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Light Summer</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.LightSummer && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.LightSummer && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Soft Summer</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.SoftSummer && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.SoftSummer && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Warm Spring</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.WarmSpring && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.WarmSpring && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Light Spring</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.LightSpring && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.LightSpring && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Bright Spring</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.BrightSpring && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.BrightSpring && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Warm Autumn</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.WarmAutumn && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.WarmAutumn && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Soft Autumn</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.SoftAutumn && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.SoftAutumn && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Deep Autumn</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.DeepAutumn && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.DeepAutumn && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Cool Winter</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.CoolWinter && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.CoolWinter && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Bright Winter</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.BrightWinter && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.BrightWinter && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
        <h4>Deep Winter</h4>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.DeepWinter && f.type === ColorFrameType.Colors)?.relativeUrlJpg}></img>
        <img className="colorPalette" alt="not found" src={ColorFrames.getAllColorFrames().find((f) => f.season === ColorSeasons.DeepWinter && f.type === ColorFrameType.Neutrals)?.relativeUrlJpg}></img>
        <br></br><br></br>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Palettes;
