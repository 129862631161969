import React, { useEffect, useState } from 'react';
import {  json, useNavigate, useParams } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { Ticket } from '../../models/Ticket';
import { TicketComment } from '../../models/TicketComment';
import { CommentType } from '../../models/CommentType';
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketResult } from '../../models/TicketResult';
import { Button } from 'react-bootstrap';
import './AdminRequestManage.css';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { registerLicense } from '@syncfusion/ej2-base'
import { ColorFramedPortrait } from '../../components/ColorFramedPortrait';
import { ColorSeasons } from '../../models/ColorSeasons';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ColorFrames } from '../../models/ColorFrames';
import { ColorFrame } from '../../models/ColorFrame';
import { Blurb, BlurbType } from '../../models/Blurb';
import { InspoImage, InspoImageType } from '../../models/InspoImage';
import Select from 'react-select';
import { Footer } from '../../components/Footer';
import { toZonedTime } from 'date-fns-tz';
import { Utils } from '../../apis/Utils';
import Loading from '../../components/Loading';
import { CropSelectionShape } from '../../models/CropSelectionShape';
import imageCompression from 'browser-image-compression';
import { handleFileUpload } from '../../lib/fileUploader';
import { UnassociatedPayment } from '../../models/UnassociatedPayment';

const AdminUnassociatedPayment = () => {
  let navigate = useNavigate(); 
  let { transactionId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [correctEmail, setCorrectEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [unassociatedPayment, setUnassociatedPayment] = useState<UnassociatedPayment>();

  let editingImage: ImageEditorComponent | null;

  useEffect(() => {
    KayliColorAPI.getUnassociatedPayments()
      .then((response)=>{
        setUnassociatedPayment(response.data.find((p) => p.transactionId === transactionId));
      })
      .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${error.response.data.exception.Message}` : KayliColorAPI.standardError);
          console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
          setLoading(false);
      });
  }, [ ]);

  const updatePayment = () => {
      setErrorMessage('');
      setLoading(true);
  
      KayliColorAPI.updateUnassociatedPayment(transactionId!, correctEmail)
          .then((response)=>{
            if(response.data.kayliColorTicketEmail) {
              setDone(true);
            }
          })
          .catch(function (error) {
              setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${error?.response?.data?.exception?.Message}` : KayliColorAPI.standardError);
              console.log(error.response ? error.response : error.request);
          })
          .finally( () => {
              setLoading(false);
          });
    }

  return (!unassociatedPayment) ? (<div><Loading loading={true} className=""></Loading></div>) : (
    <div>
      <div className='basicInfoAdmin'>
        <h5>Unassociated Payment:</h5>
        <span>{`PayPal Email: ${unassociatedPayment.payer_info.email_address}`}</span><br/>
        <span>{`Name: ${unassociatedPayment.payer_info.payer_name?.given_name}`}</span><br/>
        <span>{`Surname: ${unassociatedPayment.payer_info.payer_name?.surname}`}</span><br/>
        <span>{`Altname: ${unassociatedPayment.payer_info.payer_name?.alternate_full_name}`}</span><br/>
        <span>{`Date: ${unassociatedPayment.transaction_info.transaction_initiation_date}`}</span><br/>
        <span>{`Amount: ${unassociatedPayment.transaction_info?.transaction_amount?.value + ' ' + unassociatedPayment.transaction_info?.transaction_amount?.currency_code}`}</span><br/>
        <span>Correct Email: <input className="correctEmail" onChange = {(e) => { setCorrectEmail(e.target.value)}} value = {correctEmail}></input></span>
        <br></br>
        <Button disabled={loading} className="needsMoreInfo" onClick={updatePayment}>Associate + Move to Paid Queue</Button>
        <br></br>
      </div>
      <br></br>
      { done && <div>{'Done <3'}</div> }
      <br/>
      { errorMessage && <h2> {errorMessage} </h2> }
      <Loading loading={loading} ></Loading>
      <br/>
      <Footer></Footer>
    </div>
  );
};

export default AdminUnassociatedPayment;
