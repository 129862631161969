import React, { useEffect, useState } from 'react';
import './Admin.css';
import { Ticket } from '../../models/Ticket';
import { useNavigate } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { TicketSearch } from '../../models/TicketSearch';
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketSearchOrderBy } from '../../models/TicketSearchOrderBy';
import DataTable from 'react-data-table-component';
import { CommentType } from '../../models/CommentType';
import { toZonedTime } from 'date-fns-tz';
import Select, { MultiValue } from 'react-select';
import { Button } from 'react-bootstrap';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import { UnassociatedPayment } from '../../models/UnassociatedPayment';


function Admin() {
  let navigate = useNavigate(); 

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [unassociatedPayments, setUnassociatedPayments] = useState<UnassociatedPayment[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchRequest, setSearchRequest] = useState<TicketSearch>({ 
    statuses: [ColorAnalysisRequestState.InQueuePaid], 
    orderBy: TicketSearchOrderBy.PaidDate,
    orderByAscending: true
  });

  useEffect(() => {
    redoSearch();

    KayliColorAPI.getUnassociatedPayments()
      .then((response)=>{
        setUnassociatedPayments(response.data)
      })
      .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${error?.response?.data?.exception?.Message}` : KayliColorAPI.standardError);
          console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
          setLoading(false);
      });
  }, [ ]);

  const redoSearch = () => {
    setLoading(true);
    setErrorMessage('');

    KayliColorAPI.searchTickets(searchRequest)
      .then((response)=>{
        setTickets(response.data)
      })
      .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
          console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
          setLoading(false);
      });
  }

  const handleChange = (e : any) => {
    if(e.selectedCount > 0)
    {
      navigate('/admin/' + e.selectedRows[0].email + '/requests');
    }
  }

  const goToUnassociatedPaymentPage = (e : any) => {
    if(e.selectedCount > 0)
    {
      navigate('/admin/unassociatedPayment/' + e.selectedRows[0].transactionId);
    }
  }

  const handleStatusesChange = (values : MultiValue<{ value: ColorAnalysisRequestState, label: ColorAnalysisRequestState }>) => {
    if(values.length === 1 && values[0].value === ColorAnalysisRequestState.InQueuePaid) {
      setSearchRequest({...searchRequest, statuses: values.map(m => {return m.value}), orderBy: TicketSearchOrderBy.PaidDate});
    } else {
      setSearchRequest({...searchRequest, statuses: values.map(m => {return m.value}), orderBy: TicketSearchOrderBy.LastStatusChangeDate});
    }
  }

  function getTimeSince(date: Date | undefined): string {
    if(!date) {
      return 'N/A';
    }

    let localDate = toZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
    let nowLocal = new Date();
    let milliDiff: number = nowLocal.getTime() - localDate.getTime();
    const totalHours = Math.round(milliDiff / 1000 / 60 / 60);
    if(totalHours < 24) {
      return totalHours.toString() + ' hours';
    }
    return Math.round(totalHours / 24).toString() + ' days';
  }

  const selectStatuses = Object.values(ColorAnalysisRequestState).map((s) => { return { value: s, label: s } } );

  const columns = [
    { name: 'Email', selector: (row: Ticket) => row.email },
    { name: 'Name', selector: (row: Ticket) => `${row.nickName} ${row.lastName}` },
    { name: 'Status', selector: (row: Ticket) => row.status },
    { name: 'Time Since Paid', selector: (row: Ticket) => getTimeSince(row.paidDate) },
    { name: 'Time Since Last Status Change', selector: (row: Ticket) => getTimeSince(row.lastStatusChangeDate) },
    { name: 'Season', selector: (row: Ticket) => row.result },
    { name: 'TikTok', selector: (row: Ticket) => row.beOnTikTok ? 'Yes' : '' },
    { name: 'Needed More Info', selector: (row: Ticket) => row.comments?.some((c) => c.type === CommentType.Analyzer) ? 'Yes' : '' }
  ];

  const unassociatedPaymentsColumns = [
    { name: 'PayPal Email', selector: (row: UnassociatedPayment) => row.payer_info.email_address },
    { name: 'Name', selector: (row: UnassociatedPayment) => row.payer_info.payer_name?.given_name },
    { name: 'Surname', selector: (row: UnassociatedPayment) => row.payer_info.payer_name?.surname },
    { name: 'Altname', selector: (row: UnassociatedPayment) => row.payer_info.payer_name?.alternate_full_name },
    { name: 'Date', selector: (row: UnassociatedPayment) => getTimeSince(row.transaction_info.transaction_initiation_date) },
    { name: 'Amount', selector: (row: UnassociatedPayment) => row.transaction_info?.transaction_amount?.value + ' ' + row.transaction_info?.transaction_amount?.currency_code },
  ];

  return (
    <div>
      { unassociatedPayments.filter((p) => !p.kayliColorTicketEmail).length > 0 && <div>
        <h3>Unassociated Payments</h3> 
        <DataTable className='unassociatedPaymentsTable' columns={unassociatedPaymentsColumns} data={unassociatedPayments.filter((p) => !p.kayliColorTicketEmail)} /*fixedHeader fixedHeaderScrollHeight='200px'*/ selectableRows selectableRowsSingle onSelectedRowsChange={goToUnassociatedPaymentPage} />
        <hr></hr>
      </div> }
      <h3>Ticket Search</h3>
        <Select
          defaultValue={selectStatuses[7]}
          isMulti
          name="colors"
          options={selectStatuses}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleStatusesChange}
        />
        <Button className="croppingButton" onClick={redoSearch}>Redo Search</Button>
        <DataTable columns={columns} data={tickets} selectableRows selectableRowsSingle onSelectedRowsChange={handleChange} />
        <span>{`${tickets.length} records`}</span>
        <br/>
        <Loading loading={loading} className=""></Loading>
        { errorMessage && <h2> {errorMessage} </h2> }
        <br/>
        <div>
        Coming Soon... New Tickets in Paid Status X last 24 hours: 34
        <br/>
        Graph that shows statuses in different colors and you can choose 24 hours, 7 deflateSync, 30 deflateSync, year...
        <br/>
        </div>
        <button className="croppingButton" onClick={(e) => navigate('/admin/queueManagement')}>Manage Queues</button>
        <br/>
        <button className="croppingButton" onClick={(e) => navigate('/admin/blurbs')}>Manage Blurbs</button>
        <br/>
        <button className="croppingButton" onClick={(e) => navigate('/admin/inspos')}>Manage Inpo Pics</button>
        <Footer></Footer>
    </div>
    
    
  );
}

export default Admin;
