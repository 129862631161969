import React, { useEffect, useRef, useState } from 'react';
import {  useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { KayliColorAPI } from '../../../apis/KayliColorAPI';
import { Ticket } from '../../../models/Ticket';
import { TicketComment } from '../../../models/TicketComment';
import { CommentType } from '../../../models/CommentType';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { ColorAnalysisRequestState, ColorAnalysisRequestStateGroupings } from '../../../models/ColorAnalysisRequestState';
import { Footer } from '../../../components/Footer';
import Loading from '../../../components/Loading';
import './ViewRequest.css'
import PageTracker from '../../../components/PageTracker';
import ExamplePics from '../../../components/ExamplePics';
import { toZonedTime } from 'date-fns-tz';
import { Utils } from '../../../apis/Utils';
import { jsxs } from 'react/jsx-runtime';
import { handleFileUpload } from '../../../lib/fileUploader';
import { Header } from '../../../components/Header';

const ViewRequest: React.FC = () => {
  let navigate = useNavigate(); 
  let { email, pageNum } = useParams();
  email = email?.toLowerCase();
  const [searchParams] = useSearchParams();
  const overrideReroute = searchParams.get('overrideReroute');
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [beOnTikTok, setBeOnTikTok] = useState<boolean>(false);
  const [anythingElse, setAnythingElse] = useState<string>();
  const [selectedMainImage, setSelectedMainImage] = useState<string | undefined>(undefined);
  const [selectedMainImageAlt, setSelectedMainImageAlt] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState<TicketComment>({ body: '', email: email!, type: CommentType.Requestor, createdDate: new Date()});
  const [ticket, setTicket] = useState<Ticket>();
  const [pagesComplete, setPagesComplete] = useState<Boolean[]>([false, false, false, false, false]);

  const inputFile = useRef(null);
  const inputContextFiles = useRef(null);

  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        setTicket(response.data);
        if(ColorAnalysisRequestStateGroupings.ResultsReadyStatuses().indexOf(response.data.status) > -1 && !overrideReroute) {
          navigate('/requests/' + email + '/results');
        }
        else if(ColorAnalysisRequestStateGroupings.ReadyToPay().indexOf(response.data.status) > -1) {
          setPagesComplete([true, true, true, true, false]);
          navigateToPageNumber('4');
        }
        else if(response.data.status === ColorAnalysisRequestState.NeedMoreInformation) {
          setPagesComplete([true, false, true, true, false]);
          navigateToPageNumber('1');
        } 
        else if (ColorAnalysisRequestStateGroupings.TicketFilledOut().indexOf(response.data.status) > -1) { 
          setPagesComplete([true, true, true, true, true]);
          navigateToPageNumber('4');
        }
        setSelectedMainImage(response.data.mainImageUrl);
        setSelectedMainImageAlt(response.data.mainImageUrlAlt);
        setGalleryImages(response.data.requestImageUrls || []);
        setAnythingElse(response.data.initialRequest)
        setBeOnTikTok(response.data.beOnTikTok)
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
        setLoading(false);
      });
  }, [ ]);

  const navigateToPageNumber = (n: string) => {
    setErrorMessage('');
    navigate('/requests/' + email + `/view/${Number(n) ? Number(n) : 0}`);
  }

  const saveBasicInfo = (e: any) => {
    e.preventDefault();
    updateTicket(() => {
      pagesComplete[0] = true;
      setPagesComplete(pagesComplete);
      navigateToPageNumber('1');
    });
  }

  const saveAnythingElse = () => {
    updateTicket(() => {
      pagesComplete[3] = true;
      setPagesComplete(pagesComplete);
      navigateToPageNumber('4');
    });
  }

  const updateTicket = (finallyFunc: any) => { 
    setErrorMessage('');
    setLoading(true);
    KayliColorAPI.updateTicket(ticket!)
        .then((response)=>{
          setTicket(response.data);
        })
        .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
          console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
          setLoading(false);
          finallyFunc();
        });
  }

  const addComment = (e: any) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);
    
    KayliColorAPI.createComment(comment)
        .then((response)=>{
            ticket!.comments.push(comment);
            setTicket(ticket);
            setComment({ body: '', email: email!, type: CommentType.Requestor, createdDate: new Date()});
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  const uploadFile = (event: any) => {
    setErrorMessage('');
    setLoading(true);
    let newImageUrls: string[] = [];
    let promises: Promise<any>[] = [];

    let startingCount = !ticket?.requestImageUrls ? 0 : ticket!.requestImageUrls.length;
    for(var i = 0;i<Math.min(event.target.files.length, 15-startingCount);i++) {
      promises.push(handleFileUpload(event.target.files[i], '', setErrorMessage)
      .then((imageUrl)=>{
        newImageUrls.push(imageUrl as string);
      }));
    }

    Promise.all(promises)
      .then(results => {
        console.log('Lance 23');
        if(ticket && !ticket?.requestImageUrls) {
          ticket.requestImageUrls = [];
        }
        let newImageArray = ticket!.requestImageUrls.concat(newImageUrls);
        let updatedTicket = {...ticket!, requestImageUrls: newImageArray};
        setTicket(updatedTicket);
        setGalleryImages(newImageArray);
        KayliColorAPI.updateTicket(updatedTicket)
          .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
          })
          .finally( () => {
            setLoading(false);
          });
      })
  }

  const uploadMainFile = (file: any) => {
    if(!file) {
      return;
    }

    setErrorMessage('');
    setLoading(true);
    let isAlt: Boolean = !isEmptyOrSpaces(selectedMainImage);

    handleFileUpload(file, '', setErrorMessage)
      .then((imageUrl)=>{
        //let updatedResult = {...result!, croppedImageUrl: (imageUrl as string)};
        if(isAlt) {
          ticket!.mainImageUrlAlt = imageUrl!;
          setSelectedMainImageAlt(imageUrl!);
        } else {
          ticket!.mainImageUrl = imageUrl!;
          setSelectedMainImage(imageUrl!);
        }
        updateTicket(() => {})
      })
  }

  const submitTicket = () => {
    setErrorMessage('');
    setLoading(true);

    KayliColorAPI.submitTicket(email!)
        .then((response)=>{
            setTicket(response.data);
            if(response.data.status === ColorAnalysisRequestState.ReadyToPay) {
              navigate('/requests/' + email + '/payment');
            }
            else if(response.data.status === ColorAnalysisRequestState.InQueueUnpaid) {
              navigate('/requests/' + email + '/inqueue');
            }
            else if(response.data.status === ColorAnalysisRequestState.InQueuePaid) {
              navigate('/requests/' + email + '/alreadypaid');
            }
            else {
                setErrorMessage(`Submission failed. ${KayliColorAPI.tryLater}`);
                console.log("Incorect state after submitting:" + JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  function getImagesForGallery(): ReactImageGalleryItem[] {
    if(ticket?.requestImageUrls)
    {
        return ticket.requestImageUrls.map((url) => {
            return {
                original: url,
                thumbnail: url
            } as ReactImageGalleryItem;
        })
    }
    return [];
  }

  const getCommentDate = (date: Date) => {
    let localDate = toZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return `${Utils.getMonthName(localDate.getMonth())} ${localDate.getDate()}`;
  }

  const isEmptyOrSpaces = (str: string | undefined) => {
    if(!str) { return true }
    return str === null || str!.match(/^ *$/) !== null;
  }

  const getFinalButtonText = () => {
    if(ColorAnalysisRequestStateGroupings.ResultsReadyStatuses().indexOf(ticket?.status || ColorAnalysisRequestState.NewVerified) > -1) {
      return "View Results"
    } else if(ticket?.status === ColorAnalysisRequestState.NeedMoreInformation) {
      return "Resubmit"
    }
    else if(ticket?.status === ColorAnalysisRequestState.InQueuePaid) {
      if(ticket.comments?.some((c) => c.type === CommentType.Analyzer)) {
        return "Resubmit"
      }
      else {
        return "Already In Queue"
      }
    }
    else {
      return "Submit!"
    }
  }

  const finalButton = () => {
    updateTicket(() => {
      if(ticket?.status === ColorAnalysisRequestState.InQueuePaid) {
        if(ticket.comments?.some((c) => c.type === CommentType.Analyzer)) {
          navigate('/requests/' + email + '/alreadypaid');
        }
        else {
          //Do Nothing
        }
      }
      else if(ColorAnalysisRequestStateGroupings.ResultsReadyStatuses().indexOf(ticket?.status || ColorAnalysisRequestState.NewVerified) > -1) {
        navigate('/requests/' + email + '/results');
      } 
      else {
        submitTicket();
      }
    });
  }

  const existingLegacyPhotoAsMainPhoto = (url: string) => {
    setErrorMessage('');
    setLoading(true);
    let isAlt: Boolean = !isEmptyOrSpaces(selectedMainImage);

    let newTicket = {...ticket };
    if(isAlt) {
      newTicket.mainImageUrlAlt = url;
    } else {
      newTicket.mainImageUrl = url;
    }

    KayliColorAPI.setMainRequestImageString(newTicket as any)
        .then((response)=>{
            if(isAlt) {
              setSelectedMainImageAlt(response.data.mainImageUrlAlt);
            } else {
              setSelectedMainImage(response.data.mainImageUrl);
            }
            setTicket(response.data);
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  return !ticket ? (<div className="loadingBody">{false && (<Header/>)}<Loading loading={true} className="loadingBody"></Loading><Footer/></div>) : (
    <div>
      {false && (<Header/>)}
      <div className="viewContainer">
        <PageTracker page={+pageNum!} pageStatuses={pagesComplete} setPage={navigateToPageNumber} ></PageTracker>

        {(Number(pageNum) ? Number(pageNum) : 0) === 0 && (
          <div>
            <div className="centerIt">
              <h2>Introduce Yourself</h2>
              { errorMessage && <h2> {errorMessage} </h2> }
            </div>
            
            <div className="basicInfo">
              <form onSubmit = {saveBasicInfo}>
                <span>Nick Name*: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, nickName: e.target.value} as Ticket)}} value = {ticket?.nickName}></input></span>
                <br/>
                <span>Last Name*: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, lastName: e.target.value} as Ticket)}} value = {ticket?.lastName}></input></span>
                <br/>
                <span>Instagram: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, instagram: e.target.value} as Ticket)}} value = {ticket?.instagram}></input></span>
                <br/>
                <span>TikTok: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, tikTok: e.target.value} as Ticket)}} value = {ticket?.tikTok}></input></span>
                <br/>
                <div className="centerIt">
                  <button className="requestViewButton basicInfoButton" type='submit' disabled={loading || isEmptyOrSpaces(ticket?.nickName) || isEmptyOrSpaces(ticket?.lastName)} >Next</button>
                </div>
              </form>
            </div>

            <div className="centerIt statusMessage">
              <div style={{ visibility: ticket?.status !== ColorAnalysisRequestState.NewVerified ? 'visible': 'hidden'}}>Request Status: {ColorAnalysisRequestStateGroupings.GetPrettyStatusExplanation(ticket?.status)}</div>
            </div>
            <Loading loading={loading} ></Loading>
          </div>
        )}

        {(Number(pageNum) ? Number(pageNum) : 0) === 1 && (
          <div>
            <div className="centerIt"><h2 className="selfieTitle" >Attach Your Analysis Selfie</h2></div>
            <div className="selfieExplanation">
              <b>This is the most important part! </b> 
              I need a couple of vertical selfies from you: 
              <ul>
                <li>
                  Absolutely no makeup! I know these pictures can be unflattering and uncomfortable, 
                  but no makeup is important for acheiving an accurate result.
                </li>
                <li>
                  Please no editing the photo and no filters.
                </li>
                <li>
                  No hats, no glasses, and no bright colored clothing next to your face, please!
                </li>
                <li>
                  Make sure you have lots of natural light, but no direct sun rays. Usually standing next to a window with your
                  face pointed towards it is great! A selfie in your car is also ideal lighting.
                </li>
                <li>
                  Your face should be centered and take up most of the screen, but you should 
                  be able to see your hair and neck. 
                </li>
                <li>
                  If you have bangs/fringe, please pull it back so it does not block your face at all.  
                </li>
                <li>
                  A neutral shirt with a low 
                  neck line and a neutral colored background are best.
                </li>
                <li>
                  Hold your phone a little bit above your eye level and look up into camera lens. 
                </li>
              </ul>
            </div>
            <div className="selfieExplanation">
              
            </div>
            <div className="selfieExplanation">
                
            </div>
            <div className="selfieExplanation">
              The second selfie is optional, but it can be very helpful if you provide a second selfie in different lighting.
            </div>
            { errorMessage && <div className="centerIt"><h2> {errorMessage} </h2></div> }

            { ticket?.comments && ticket?.comments.length > 0 &&
              <div className="commentContainer">
                <h6><b>Comments from Kayli:</b></h6>
                <div>
                  <ul>{ticket?.comments.map((r, i) => <li key={i}><b>{r.type === CommentType.Analyzer ? "Kayli" : ticket?.nickName} on {getCommentDate(r.createdDate)}:</b> {r.body}</li>)}</ul>
                </div>
                <form className="commentForm" onSubmit = {addComment}>
                  <span>Add Comment: <input className="enterEmail" onChange = {(e) => { setComment({...comment, body: e.target.value}) }} value = {comment?.body}></input></span>
                  <button className="sendEmail greenButton" type = 'submit' disabled = {loading || isEmptyOrSpaces(comment?.body)} ><i className="icon bi bi-file-earmark-arrow-up"></i></button>
                </form>
              </div>
            }

            <ExamplePics hasGoodPic={true} picOne='../../../goodPic.jpeg' picTwo='../../../badPicLighting.jpeg'
              text11="-Even Light" text12="-Whole Face" text13="-No Makeup" text14="-Neutral shirt" 
              text21="-Bad Lighting" text22="-Shadows" text23="-Direct Sun" text24="-Off-center" text25="-Face Angled Away" />
            <ExamplePics hasGoodPic={false} picOne='../../../badPicGlasses.jpeg' picTwo='../../../badPicMakeup.jpeg'
              text11="-No Glasses" text12="-No Jewellery" text13="-No Hats" text14="-No Scarfs" 
              text21="-No Makeup" text22="-Not even a little" text23="-No editing" text24="-No filters" />
            <ExamplePics hasGoodPic={false} picOne='../../../badPicHair.jpeg' picTwo='../../../badPicBrightColor.jpeg'
              text11="-No Hair in Face"
              text21="-No colorful clothes near face" />

            {ticket.legacyImport && ticket.requestImageUrls && ticket.requestImageUrls.length > 0 && (<div className="legacyPhotosContainer centerIt">
              You already emailed me these photos. If one of these matches all of the requirements above, click on that photo to use it instead of uploading a new photo.
              <div className="legacyPhotosSubContainer">
                  {ticket.requestImageUrls.map((url, i) => (
                    <div className="frameTile" key={i}>
                      <img onClick={(e) => {existingLegacyPhotoAsMainPhoto(url)}} alt="!!!" width={"80px"} src={url} />   
                    </div>
                  ))}
                </div>
            </div>)}

            <Loading loading={loading} ></Loading>
            { errorMessage && <div className="centerIt"><h2> {errorMessage} </h2></div> }

            <div className="uploadImageRow">
              <div className="uploadImageContainer">
                Primary
                <img onClick={(e) => (inputFile.current as any).click()} alt="not found" width={"85px"} src={selectedMainImage || "../../../portraitPlaceholder.jpg"} />
                <button className="requestViewButton removeButton" disabled={loading || !selectedMainImage} onClick={(e) => setSelectedMainImage(undefined)} >Remove</button>
              </div>
              <div className="uploadImageContainer">
                Optional
                <img onClick={(e) => (inputFile.current as any).click()} alt="not found" width={"85px"} src={selectedMainImageAlt || "../../../portraitPlaceholder.jpg"} />
                <button className="requestViewButton removeButton" disabled={loading || !selectedMainImageAlt} onClick={(e) => setSelectedMainImageAlt(undefined)} >Remove</button>
              </div>
            </div>

              <div className="buttonsContainer">
                <input className="contextButton mainFileSelect" ref={inputFile} type="file" name="myImage"
                  disabled={!isEmptyOrSpaces(selectedMainImage) && !isEmptyOrSpaces(selectedMainImageAlt)}
                  onChange={(event: any) => {
                    uploadMainFile(event.target.files[0])
                  }}
                ></input>
                <div className="centerIt">
                  <button className="contextButton requestViewButton" disabled={!isEmptyOrSpaces(selectedMainImage) && !isEmptyOrSpaces(selectedMainImageAlt)} onClick={(e) => (inputFile.current as any).click()}>Upload</button>
                  <br/>
                  <button className="contextButton requestViewButton" disabled={loading || !ticket?.mainImageUrl} onClick={(e) => {pagesComplete[1] = true; setPagesComplete(pagesComplete); navigateToPageNumber('2') }}>Next</button>
                </div>
              </div>

          </div>
        )}

        {(Number(pageNum) ? Number(pageNum) : 0) === 2 && (
          <div>
            <div className="centerIt">
              <h2 className="selfieTitle" >Context Pics</h2>
              <div className="selfieExplanation">
                This is optional, but really helpful! Add some extra photos to give me context as I analyze your color season. 
                <br/><br/>
                For example: Pictures of you looking fabulous. Pictures of you as a kid or teen. Pictures of you before you dyed your hair. Etc...
              </div>
            </div>

            { (galleryImages && galleryImages?.length > 0) && <ImageGallery items={getImagesForGallery()} /> }
            <br></br>
            <Loading loading={loading} ></Loading>
            { errorMessage && <h2> {errorMessage} </h2> }

            <input
              ref={inputContextFiles}
              type="file"
              accept="image/*"
              name="myContextImage"
              className="mainFileSelect"
              multiple
              disabled={!!(ticket?.requestImageUrls?.length) && ticket.requestImageUrls.length >= 15}
              onChange={(event: any) => {
                uploadFile(event);
              }}
            />
            <div className="centerIt">
              { ticket?.requestImageUrls && ticket.requestImageUrls.length > 0 &&
                <div className="deleteAllPhotosButton"><button disabled={loading} onClick={(e) => {ticket.requestImageUrls = []; setTicket(ticket); setGalleryImages([]); updateTicket(() => {});} }>Delete All Photos</button></div>
              }
              <button disabled={loading} onClick={(e) => (inputContextFiles.current as any).click()}>Upload Photos</button>
              { !!(ticket?.requestImageUrls?.length) && ticket.requestImageUrls.length >= 15 && 
                <div className="maxPhotoLimit">Reached max limit of 15 photos</div>
              }
              { (!(ticket?.requestImageUrls?.length) || ticket.requestImageUrls.length < 15) && <br/> }
              <button className="contextPhotoButton" disabled={loading} onClick={(e) => {pagesComplete[2] = true; setPagesComplete(pagesComplete); navigateToPageNumber('3')}}>Next</button>
            </div>
          </div>
        )}

        {(Number(pageNum) ? Number(pageNum) : 0) === 3 && (
          <div>
            <div className="centerIt"><h2 className="selfieTitle" >Anything else I should know?</h2></div>
            <div className="selfieExplanation">
              For example: Do you have a favorite color? Do you already suspect you might be a particular season? Do you already suspect you are warm or cool toned? Do have a preferred jewelry metal? What color was your hair as a kid? Feel free to write any information you think may be helpful below.
            </div>
            <div className="centerIt anythingElseContainer">
              <textarea maxLength={750} className="anythingElse" value={anythingElse} 
                onChange={e => {setAnythingElse(e.target.value); ticket.initialRequest = e.target.value; setTicket(ticket)} } />
              <div className="charCount">{anythingElse?.length} / 750</div>
            </div>
            <Loading loading={loading} ></Loading>
            { errorMessage && <h2> {errorMessage} </h2> }
            <div className="centerIt"><button className="contextButton" disabled={loading} onClick={(e) => saveAnythingElse()}>Next</button></div>
          </div>
        )}

        {(Number(pageNum) ? Number(pageNum) : 0) === 4 && (
          <div>
            <div className="centerIt"><h2 className="selfieTitle" >Would you like to be on my TikTok?</h2></div>
            <div className="selfieExplanation">
              I occasionally feature people on my TikTok account to help others see examples of people who might share their features being color-analyzed. 
              <br/>
              Usually, I share their analysis selfie, and some context photos or and/or screenshots of their instagram or TikTok photos and videos. 
              <br/>
              If non-vonlunteers are in said content I will crop them out or hide their faces, so you're only consenting to images of yourself being featured. 
              <br/>
              If you're uncomfortable having photos of you online, I completely understand! Just check "no" and your images and results will be private. 
              <br/>
              If you say "yes" and include your TikTok handle then I will tag you in the video.
            </div>
            <div className="centerIt">
              <div className="radioContainer">
                <br/>
                <input
                  type="radio"
                  name="site_name"
                  value={"asdf"}
                  checked={!beOnTikTok}
                  onChange={(e) => {setBeOnTikTok(false); ticket!.beOnTikTok = false; setTicket(ticket);} } /> No, thanks! I don't want to be featured.
                <br/><br/>
                <input
                  type="radio"
                  name="site_name"
                  value={"asdf"}
                  checked={beOnTikTok}
                  onChange={(e) => {setBeOnTikTok(true); ticket!.beOnTikTok = true; setTicket(ticket);} } /> Yes, please! I'd love to be featured.
                
                {beOnTikTok && (
                  <div className="rightIt">
                    <span>Instagram: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, instagram: e.target.value} as Ticket)}} value = {ticket?.instagram}></input></span>
                    <br/>
                    <span>TikTok: <input className="requestViewInput" onChange = {(e) => { setTicket({...ticket, tikTok: e.target.value} as Ticket)}} value = {ticket?.tikTok}></input></span>
                  </div>
                )}
                
                
              </div>
              <br/>

              <Loading loading={loading} ></Loading>
              { errorMessage && <h2> {errorMessage} </h2> }
              {ticket?.status === ColorAnalysisRequestState.InQueuePaid && (
                <div className="alreadyInQueueMessage" >You have paid and are in the queue. I'll have your results ready ASAP and will email you when they are done!</div>
              )}
              {(ticket?.status !== ColorAnalysisRequestState.InQueuePaid || ticket.comments?.some((c) => c.type === CommentType.Analyzer)) && (
                <button className="contextButton" disabled={loading} onClick={(e) => finalButton()}>
                    {getFinalButtonText()}
                </button>
              )}
            </div>
            <br/>
            
          </div>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ViewRequest;
