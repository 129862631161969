import './Results.css';
import React, { useEffect, useState } from 'react';
import {  json, useNavigate, useParams } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { Ticket } from '../../models/Ticket';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketResult } from '../../models/TicketResult';
import { Button } from 'react-bootstrap';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { registerLicense } from '@syncfusion/ej2-base'
import { ColorFramedPortrait } from '../../components/ColorFramedPortrait';
import { ColorSeasons, ColorSeasonsTools } from '../../models/ColorSeasons';
import Dropdown from 'react-dropdown';
import { ColorFrames } from '../../models/ColorFrames';
import { ColorFrame } from '../../models/ColorFrame';
import { Blurb, BlurbType } from '../../models/Blurb';
import { InspoImage, InspoImageType } from '../../models/InspoImage';
import { ColorFrameType } from '../../models/ColorFrameType';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';

const Results = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();
  const [ticket, setTicket] = useState<Ticket>();
  const [result, setResult] = useState<TicketResult>();
  const colorFrames = ColorFrames.getAllColorFrames();
  const seasonsForMultiDropDown = Object.values(ColorSeasons).map((s) => { return { value: s, label: s } } );
  const seasonsForDropDown = Object.values(ColorSeasons);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [allInspoImages, setAllInspoImages] = useState<InspoImage[]>([]);
  const [relevantHairInspo, setRelevantHairInspo] = useState<InspoImage[]>([]);
  const [relevantMakeupInspo, setRelevantMakeupInspo] = useState<InspoImage[]>([]);
  const [choosenHairInspo, setChoosenHairInspo] = useState<InspoImage[]>([]);
  const [choosenMakeupInspo, setChoosenMakeupInspo] = useState<InspoImage[]>([]);
  const [allBlurbs, setAllBlurbs] = useState<Blurb[]>([]);
  const [colorFrameColors, setColorFrameColors] = useState<ColorFrame>(colorFrames.find((f) => f.season === ColorSeasons.CoolSummer && f.type === ColorFrameType.Colors)!);
  const [colorFrameNeutrals, setColorFrameNeutrals] = useState<ColorFrame>(colorFrames.find((f) => f.season === ColorSeasons.CoolSummer && f.type === ColorFrameType.Neutrals)!);
  const [colorFrameSisters, setColorFrameSisters] = useState<ColorFrame[]>(colorFrames.filter((f) => f.season === ColorSeasons.LightSummer && f.type === ColorFrameType.Colors)!);
  const [colorFrameOpposites, setColorFrameOpposites] = useState<ColorFrame[]>(colorFrames.filter((f) => f.season === ColorSeasons.DeepWinter && f.type === ColorFrameType.Colors)!);

  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        let adminCode = localStorage.getItem("adminCode");
        if(!adminCode && response.data.status === ColorAnalysisRequestState.ResultsDelivered) {
          KayliColorAPI.resultsOpened(response.data.email)
            .then((newResponse)=>{
              setTicket(newResponse.data);
            })
            .catch(function (error) {
              console.log(error.response ? error.response : error.request);
            })
        }
        else {
        setTicket(response.data);
        }
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })

    KayliColorAPI.getResult(email!)
      .then((response)=>{
        if(!response.data.result) {
          let adminCode = localStorage.getItem("adminCode");
          if(adminCode) {
            navigate('admin/requests/' + email);
          } else {
            navigate('requests/' + email + '/view');
          }
        }
        setResult(response.data);
        setColorFrameColors(colorFrames.find((f) => f.season === response.data.result && f.type === ColorFrameType.Colors)!);
        setColorFrameNeutrals(colorFrames.find((f) => f.season === response.data.result && f.type === ColorFrameType.Neutrals)!);
        setColorFrameSisters(colorFrames.filter((f) => response.data.sisterSeasons.indexOf(f.season) > -1 && f.type === ColorFrameType.Colors))
        setColorFrameOpposites(colorFrames.filter((f) => response.data.oppositeSeasons.indexOf(f.season) > -1 && f.type === ColorFrameType.Colors))
        console.log(JSON.stringify(colorFrames.filter((f) => response.data.sisterSeasons.indexOf(f.season) > -1 && f.type === ColorFrameType.Colors)));
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })

    KayliColorAPI.getAllBlurbs()
      .then((response)=>{
        setAllBlurbs(response.data)
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  function getImagesForGallery(imageUrls: string[] | undefined): ReactImageGalleryItem[] {
    if(imageUrls)
    {
      return imageUrls.map((url) => {
          return {
              original: url,
              thumbnail: url
          } as ReactImageGalleryItem;
      })
    }
    return [];
  }

  const goBack = () => {
    let adminCode = localStorage.getItem("adminCode");
    if(!adminCode) {
      navigate('/admin');
    } else {
      navigate('requests/' + email + '/view');
    }
    
  }

  return (!ticket || !result) ? (<div className="loadingBody"><Loading loading={true} className="loadingBody"></Loading><Footer/></div>) : (
    <div>
      <div className="mainContainer">
        {result && ticket && (
          <div>
            { errorMessage && <h2> {errorMessage} </h2> }

            <div className="mainHeader">{ColorSeasonsTools.ToPrettyText(result?.result, false)}</div>

            {result?.cropPath && (
              <div className="centerIt">
                <div>
                  <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                    svgClass="svgBoth" hrefBase={result?.croppedImageUrl || ticket?.mainImageUrl || ''} hrefCover={colorFrameColors.relativeUrl} path={result.cropPath} />
                </div>
                <div className="topMargin">
                  <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                    svgClass="svgBoth" labelClass='bigLabel' label="Neutrals" hrefBase={result?.croppedImageUrl || ticket?.mainImageUrl || ''} hrefCover={colorFrameNeutrals.relativeUrl} path={result.cropPath} />
                </div>

                <br/>
                <div className="centerIt">Color Palette For Download</div>
                <img className="colorPalette" alt="not found" src={colorFrameColors.relativeUrlJpg}></img>
                <img className="colorPalette" alt="not found" src={colorFrameNeutrals.relativeUrlJpg}></img>
              </div>
            )}

            <br />

            <div className="sectionHeader">ABOUT {ColorSeasonsTools.ToPrettyText(result?.result, true)}S</div>
            <div className="bodyContent">{result?.introduction}</div>
            <div className="subSectionHeader">Metals</div>
            <div className="bodyContent">{allBlurbs.find(b => b.primarySeason === result?.result && b.type === BlurbType.Metals)?.content}</div>

            {(result?.hairInspoUrls && result?.hairInspoUrls.length > 0) && <div>
              <div className="subSectionHeader">Hair Inspo</div>
              <div className="bodyContent hairBlurb">{result.hairInspoExplanation}</div>
              <div className="inspo"><ImageGallery items={getImagesForGallery(result?.hairInspoUrls)} /></div>
            </div> }

            {(result?.makeUpInspoUrls && result?.makeUpInspoUrls.length > 0) && <div>
              <div className="subSectionHeader">Makeup Inspo</div>
              <div className="bodyContent hairBlurb">{result.makeupInspoExplanation}</div>
              <div className="inspo"><ImageGallery items={getImagesForGallery(result?.makeUpInspoUrls)} /></div>
            </div> }

            {colorFrameSisters && colorFrameSisters.map((f, i) => (
              <div>
                <div className="subSectionHeader">Sister Seasons</div>
                <div className="bodyContent pairContent">{allBlurbs.find(b => b.primarySeason === result?.result && b.type === BlurbType.SisterSeasons && b.otherSeason === f.season)?.content}</div>
                <div key={i} className="seasonPair" style={{ display: result ? 'block': 'none'}}>
                  <div className="seasonLeft">
                    <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                      labelClass='smallLabel' svgClass="svgLeft" label={`Your Season: ${ColorSeasonsTools.ToPrettyText(result?.result, false)}`} 
                      hrefBase={result?.croppedImageUrl || ''} hrefCover={colorFrameColors.relativeUrl} path={result?.cropPath || 'M 50, 0 a 100,100 0 1,0 1,0'} />
                  </div>
                  <div className="seasonRight">
                    <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                      labelClass='smallLabel' svgClass="svgRight" label={`Sister Season: ${ColorSeasonsTools.ToPrettyText(f.season, false)}`}
                      hrefBase={result?.croppedImageUrl || ''} hrefCover={f.relativeUrl} path={result?.cropPath || 'M 50, 0 a 100,100 0 1,0 1,0'} />
                  </div>
                </div>
              </div>
            ))}
            
            
            {colorFrameOpposites && colorFrameOpposites.map((f, i) => (
              <div>
                <div className="subSectionHeader">Opposite Seasons</div>
                <div className="bodyContent pairContent">{allBlurbs.find(b => b.primarySeason === result?.result && b.type === BlurbType.OppositeSeasons && b.otherSeason === f.season)?.content}</div>
                <div key={i} className="seasonPair" style={{ display: colorFrameOpposites ? 'block': 'none'}}>
                  <div className="seasonLeft">
                    <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                      labelClass='smallLabel' svgClass="svgLeft" label={`Your Season: ${ColorSeasonsTools.ToPrettyText(result?.result, false)}`}
                      hrefBase={result?.croppedImageUrl || ticket?.mainImageUrl || ''} hrefCover={colorFrameColors.relativeUrl} path={result?.cropPath || 'M 50, 0 a 100,100 0 1,0 1,0'} />
                  </div>
                  <div className="seasonRight">
                    <ColorFramedPortrait viewPortHeight={result!.croppedImageHeightPixels} viewPortWidth={result!.croppedImageWidthPixels}
                      labelClass='smallLabel' svgClass="svgRight" label={`Opposite Season: ${ColorSeasonsTools.ToPrettyText(f.season, false)}`}
                      hrefBase={result?.croppedImageUrl || ticket?.mainImageUrl || ''} hrefCover={f.relativeUrl} path={result?.cropPath || 'M 50, 0 a 100,100 0 1,0 1,0'} />
                  </div>
                </div>
              </div>
            ))}

            <div className="followMe">
              <div className="followMeSub">
                Follow me on TikTok if you're interested in learning more about Color Seasons or Pinterest if you want to see more inspo and shopping links.
              </div>
              <div className="followMeSub">
                Please share my links with your friends and family that may be interested!
              </div>
              <div className="followMeSub">
                I hope that learning your color season helps you feel the confidence you deserve!
              </div>
            </div>
            
          </div>
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default Results;
